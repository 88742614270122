<template lang="pug">
  .verification-success-message-container
    .verification-success-message__img
      img(src='/awesome-icons/success.svg')
    .verification-success-message__subheader
      span Ваши данные и фото отправлены на проверку
    .verification-success-message__desc
      span Проверка в среднем занимает 1-2 рабочих дня
      br
      br
      span Вам будет отправлено уведомление об изменении статуса проверки, также текущий статус можно узнать в разделе “Профиль”
</template>

<script>
export default {
  name: "VerificationSuccessMessage",
};
</script>

<style scoped lang="scss">
.verification-success-message {
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  &__subheader {
    margin-top: 18px;
    margin-bottom: 9px;

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $gray800;
    }
  }

  &__desc {
    max-width: 400px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray600;
    }
  }
}
</style>