<template lang='pug'>
  .upload-photo-container(:class='{ "have-image": !isHaveImage }')
    el-upload(
      action=''
      ref='upload'
      :show-file-list='false'
      :http-request='loadImage'
      :before-upload='beforeUpload'
      :auto-load='false'
      :limit='5'
      :drag='isHaveImage'
    )
      .upload-photo-block__content(v-if='isHaveImage')
        .upload-photo-block__title
          span Загрузите или перетащите сюда несколько фото паспорта и фото вашего лица с паспортом
        .upload-photo-block__format
          span Формат: png, jpg, jpeg
          br
          span Максимальный размер: 2 МБ
        .upload-photo-block__attention
          .upload-photo-block__attention-subtitle
            span Обратите внимание:
          .upload-photo-block__attention-container
            ul.upload-photo-block__attention-desc
              li.upload-photo-block__attention-desc__text текст в документе хорошо читается
              li.upload-photo-block__attention-desc__text вся страница с фото попала в область
              li.upload-photo-block__attention-desc__text нет бликов
            .upload-photo-block__btn
              button.awesome-btn
                span Загрузить фото
      .upload-photo-list-container(slot='tip')
        .upload-photo-list__item(v-for='image in images')
          el-tooltip(
            v-if="Object.keys(image)[0].length >= 28"
            effect="dark"
            placement="top"
            :content="Object.keys(image)[0]"
          )
            span {{ Object.keys(image)[0] }}
          span(v-else) {{ Object.keys(image)[0] }}
          img(src='/awesome-icons/delete.svg' @click='handleRemove(Object.keys(image)[0])')
        button.awesome-btn.awesome-btn-outline(@click='loadMoreFiles' v-if='!isHaveImage && imageCount < 5')
          span Загрузить еще
</template>

<script>
import cfg from '../../../../../../../config';

export default {
  name: 'VerificationUploadPhoto',
  props: {
    kyc: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      images: [],
      form: new FormData(),
      imageCount: 0,
    };
  },
  computed: {
    isHaveImage() {
      return this.imageCount < 1;
    },
    isMaxFileSize() {
      return parseInt(cfg.message_max_filesize);
    },
  },
  watch: {
    imageCount: {
      handler(count) {
        let disabled = null;
        if (count === 0) {
          disabled = true;
          this.$emit('inputUploadPhoto', this.images, count, disabled);
        } else {
          disabled = false;
          this.$emit('inputUploadPhoto', this.images, count, disabled);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    const { images, imageCount } = this.kyc;
    this.images = images;
    this.imageCount = imageCount;
  },
  methods: {
    loadImage(data) {
      this.images.push({ [data.file.name]: data.file });
      this.imageCount++;
    },
    beforeUpload(file) {
      const types = [
        'image/jpeg',
        'image/jpg',
        'image/png',
      ];
      if (types.indexOf(file.type) === -1 || file.size > this.isMaxFileSize) {
        this.$message.error('Изображение может быть только формата jpg/jpeg/png и не более 2 МБ');
        return false;
      } else if (this.images.find(item => Object.keys(item)[0] === file.name)) {
        this.$message.error('Файл с таким именем уже существует');
        return false;
      }
      return true;
    },
    handleRemove(fileName) {
      this.images = this.images.filter(obj => Object.keys(obj).indexOf(fileName) === -1);
      this.$refs.upload.clearFiles();
      this.imageCount--;
    },
    loadMoreFiles() {
      this.$refs.upload.$el.querySelector('input').click();
    },
  },
};
</script>

<style lang='scss'>
.upload-photo {
  &-container {
    margin-top: 23px;

    &.have-image {
      margin-top: 0;
    }

    .el-upload {
      width: 100%;
      height: 100%;

      &-dragger {
        border: 1px dashed $blue300;
        border-radius: 16px;
        width: 100%;
        height: 100%;
      }

      &-list {
        &__item {
          &:hover {
            background: none;
          }
        }
      }
    }
  }

  &-block {
    &__content {
      display: flex;
      flex-direction: column;
      padding: 18px 16px 16px;
      gap: 8px;
      text-align: left;
    }

    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $gray800;
    }

    &__format {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $gray600;
    }

    &__attention {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $gray600;

      &-subtitle {
        font-weight: 500;
      }

      &-desc {
        padding-left: 20px;
        margin: 0;
        font-weight: 400;

        &__text {
          margin-bottom: 0 !important;
        }
      }

      &-container {
        display: flex;
        justify-content: space-between;
      }
    }

    &__btn {
      display: flex;
      align-items: flex-end;
    }
  }

  &-list {
    &-container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 1 240px;
      border: 1px solid $blue300;
      padding: 12px 16px;
      height: 40px;
      border-radius: 8px;
      overflow: hidden;

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $gray800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        cursor: pointer;
      }

      &:hover {
        background-color: $blue100;
      }
    }
  }
}
</style>