<template lang="pug">
  .awesome-wizard-step
    .awesome-wizard-step__number-wrapper(:class="{ active: isActive, completed: isCompleted }")
      .awesome-wizard-step__number(:class="{ active: isActive }") {{ stepNumber }}
    .awesome-wizard-step__title(v-show='isActive') {{ title }}
    .awesome-wizard-step__stepper
</template>

<script>
export default {
  name: "WizardStep",
  props: {
    title: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.awesome-wizard-step {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &__number-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0 2px;
    border: 1.5px solid $blue200;
    border-radius: 8px;

    &.active {
      border: 1.5px solid $blue600;
    }

    &.completed {
      background: $blue100;
    }
  }

  &__number {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $gray600;

    &.active {
      color: $blue600;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $blue600;
  }

  &__stepper::after {
    border: 1px solid $gray600;
    width: 8px;
    height: 0;
    margin-right: 10px;
    display: block;
    content: "";
  }

  &:last-child &__stepper::after {
    display: none;
  }
}
</style>