<template lang='pug'>
el-dialog.dialog-profile-verification(
  v-loading="isKycSubmitting"
  :title='dialogTitle'
  :visible.sync='dialogVisible'
  :show-close='false'
  width='545px'
  class='awesome-el-dialog'
  @close='handleCloseDialog'
)
  .dialog-profile-verification__content
    .wizard-container
      .wizard-steps(v-if='!isSuccessVerification')
        WizardStep(
          v-for='(step, index) in steps'
          :key='step.title'
          :title='step.title'
          :step-number='index + 1'
          :is-active='currentStep === index + 1'
          :is-completed='currentStep > index + 1'
        )
      .wizard-step-content
        template(v-if='currentStep === 1')
          VerificationPersonalInformation(@inputPersonalInformation='getPersonalInformation' :kyc='kyc')
        template(v-if='currentStep === 2')
          VerificationPassportData(@inputPassportData='getPassportData' :kyc='kyc')
        template(v-if='currentStep === 3')
          VerificationUploadPhoto(@inputUploadPhoto='getUploadImage' :kyc='kyc')
        template(v-if='currentStep === 4')
          VerificationSuccessMessage
  .dialog-profile-verification__footer(slot='footer')
    .dialog-profile-verification__image-count(v-if='currentStep === 3')
      span {{ `Загружено: ${ kyc.imageCount } из 5 фото` }}
    .dialog-profile-verification__footer-btn
      button.awesome-btn.awesome-btn-outline(@click='handleCancel' v-if='currentStep === 1 || currentStep === 4')
        span {{ isSuccessVerification ? 'Закрыть окно' : 'Отменить' }}
      button.awesome-btn.awesome-btn-outline(@click='setCurrentStepBack' v-else)
        span Назад
      button.awesome-btn(@click='setCurrentStepPrev' v-if='!isSuccessVerification' :disabled='isDisabled || isKycSubmitting')
        span {{ currentStep === 3 ? 'Отправить заявку' : 'Следующий шаг' }}
</template>

<script>
import WizardStep from '@/components/AwesomeComponents/WizardStep.vue';
import VerificationPersonalInformation
  from '@/components/AwesomeComponents/Account/Profile/Dialogs/Components/VerificationPersonalInformation.vue';
import VerificationPassportData
  from '@/components/AwesomeComponents/Account/Profile/Dialogs/Components/VerificationPassportData.vue';
import VerificationUploadPhoto
  from '@/components/AwesomeComponents/Account/Profile/Dialogs/Components/VerificationUploadPhoto.vue';
import VerificationSuccessMessage
  from '@/components/AwesomeComponents/Account/Profile/Dialogs/Components/VerificationSuccessMessage.vue';
import {PROFILE_KYC, USER_PROFILE} from '@/store/actions/user';

export default {
  name: 'DialogProfileVerification',
  components: {
    VerificationSuccessMessage,
    VerificationUploadPhoto,
    VerificationPassportData,
    VerificationPersonalInformation,
    WizardStep,
  },
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      steps: [
        {
          title: 'Персональные данные',
        },
        {
          title: 'Паспортные данные',
        },
        {
          title: 'Загрузка фото',
        },
      ],
      currentStep: 1,
      kyc: {
        first_name: '',
        last_name: '',
        patronymic: '',
        birthdate: '',
        passport_number: '',
        passport_registered_at: '',
        passport_registration_place: '',
        address: '',
        images: [],
        imageCount: 0,
        checked: false,
      },
      form: new FormData(),
      isDisabled: false,
      isKycSubmitting: false,
    };
  },
  computed: {
    isSuccessVerification() {
      return this.currentStep === 4;
    },
    dialogTitle() {
      if (!this.isSuccessVerification) {
        return 'Верификация личности';
      } else {
        return '';
      }
    },
  },
  mounted() {
    this.dialogVisible = this.opened;
  },
  methods: {
    handleCloseDialog() {
      this.$emit('close');
    },
    handleCancel() {
      this.handleCloseDialog();
      if (this.currentStep === 4) this.$store.dispatch(USER_PROFILE);
    },
    setCurrentStepBack() {
      this.currentStep--;
    },
    setCurrentStepPrev() {
      if (this.currentStep < 3) {
        this.currentStep++;
        return;
      }
      this.onSubmit();
    },
    getPersonalInformation({first_name, last_name, patronymic, birthdate, checked}, disabled) {
      this.kyc = {
        ...this.kyc,
        first_name,
        last_name,
        patronymic,
        birthdate,
        checked,
      };
      this.isDisabled = disabled;
    },
    getPassportData({passport_number, passport_registered_at, passport_registration_place, address}, disabled) {
      this.kyc = {
        ...this.kyc,
        passport_number,
        passport_registered_at,
        passport_registration_place,
        address,
      };
      this.isDisabled = disabled;
    },
    getUploadImage(images, imageCount, disabled) {
      this.kyc = {
        ...this.kyc,
        images,
        imageCount,
      };
      this.isDisabled = disabled;
    },
    async onSubmit() {
      this.isKycSubmitting = true;
      try {
        const {checked, imageCount, images, ...filteredKyc} = this.kyc;

        for (let i = 0; i < imageCount; i++) {
          this.form.append(`images[${i}]`, Object.values(images[i])[0]);
        }
        for (const [property, value] of Object.entries(filteredKyc)) {
          this.form.append(property, value);
        }

        const response = await this.$store.dispatch(PROFILE_KYC, this.form)
        if (response) this.currentStep = 4;
      } catch (error) {
        if (error && error.errors) {
          const errors = error.errors;
          let message = '';
          for (const error_type in errors) {
            for (const errorItem of error.errors[error_type]) {
              message += `${errorItem}<br>`;
            }
          }
          this.$message.error({dangerouslyUseHTMLString: true, message});
          this.handleErrors(errors);
        } else {
          this.$message.error({dangerouslyUseHTMLString: true, message: error.message});
          this.handleCloseDialog();
        }
      }
      this.isKycSubmitting = false;
    },
    handleErrors(errors) {
      if (errors.first_name || errors.last_name || errors.patronymic || errors.birthdate) {
        this.currentStep = 1;
      } else if (errors.passport_number || errors.passport_registered_at || errors.passport_registration_place || errors.address) {
        this.currentStep = 2;
      } else if (errors.images) {
        this.currentStep = 3;
      }
    },
  },
};
</script>

<style lang='scss'>
.dialog-profile-verification {
  &__content {
    .wizard {
      &-container {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        height: 243px;
      }

      &-steps {
        display: flex;
        align-items: center;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 22px;

    &-btn {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      gap: 16px;
    }
  }

  &__image-count {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $gray600;
  }
}
</style>
