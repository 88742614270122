<template lang='pug'>
  .passport-data-container
    .passport-data__content
      .passport-data__first-container
        .passport-data__input
          input(
            v-model='passportData.passport_number'
            placeholder='ID / Номер паспорта',
            type='text',
          )
        .passport-data__input
          el-date-picker.w-100(
            v-model='passportData.passport_registered_at'
            placeholder='Дата выдачи паспорта',
            format='dd-MM-yyyy',
            value-format='dd/MM/yyyy',
            :clearable='false',
            :picker-options="{ disabledDate: disabledDate }",
          )
      .passport-data__second-container
        .passport-data__input
          input(
            v-model='passportData.passport_registration_place'
            placeholder='Страна выдачи паспорта',
            type='text',
          )
        .passport-data__input
          input(
            v-model='passportData.address'
            placeholder='Адрес постоянной регистрации',
            type='text',
          )
</template>

<script>
import utils from "@/components/mixins/utils";

export default {
  name: 'VerificationPassportData',
  mixins: [utils],
  props: {
    kyc: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      passportData: {},
    };
  },
  watch: {
    passportData: {
      handler({ passport_number, passport_registered_at, passport_registration_place, address }) {
        let disabled = null;
        if (passport_number === '' || passport_registered_at === '' || passport_registration_place === '' || address === '') {
          disabled = true;
        } else {
          disabled = false;
        }
        this.$emit('inputPassportData', {
          passport_number,
          passport_registered_at,
          passport_registration_place,
          address,
        }, disabled);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    const { passport_number, passport_registered_at, passport_registration_place, address } = this.kyc;
    this.passportData = { passport_number, passport_registered_at, passport_registration_place, address };
  },
};
</script>

<style scoped lang='scss'>
.passport-data {
  &-container {
    margin-top: 23px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__first-container {
    display: flex;
    gap: 16px;

    .el-date-picker {
      width: 100%;
    }
  }

  &__second-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__input {
    flex: 1;
  }
}
</style>