<template lang='pug'>
  .personal-information-container
    .personal-information__subtitle
      span Согласно закону мы обязаны проверить удостоверение личности, чтобы другой человек не зарегистрировал аккаунт на Ваше имя
    .personal-information__content
      .personal-information__input
        input(
          placeholder='Фамилия',
          v-model='personalInformation.last_name'
          type='text',
        )
      .personal-information__input
        input(
          placeholder='Имя',
          v-model='personalInformation.first_name'
          type='text',
        )
      .personal-information__input
        input(
          placeholder='Отчество (при наличии)',
          v-model='personalInformation.patronymic'
          type='text',
        )
      .personal-information__input
        el-date-picker.w-100(
          v-model='personalInformation.birthdate'
          placeholder='Дата рождения',
          format='dd-MM-yyyy',
          value-format='dd/MM/yyyy',
          :picker-options="{ disabledDate: disabledDate }"
          :clearable='false',
        )
      .personal-information__confirm(:class='{ "checked": personalInformation.checked }')
        label.awesome-checkbox-label
          span.awesome-checkbox-text(:class='{ "checked": personalInformation.checked }') Я даю согласие на обработку персональных данных
          input(type='checkbox' v-model='personalInformation.checked' :class='{ "awesome-custom-checkbox": true, "checked": personalInformation.checked }')
          .awesome-checkbox-inner
            .awesome-checkbox-square
</template>

<script>
import utils from "@/components/mixins/utils";

export default {
  name: 'VerificationPersonalInformation',
  mixins: [utils],
  props: {
    kyc: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      personalInformation: {},
    };
  },
  watch: {
    personalInformation: {
      handler({ first_name, last_name, patronymic, birthdate, checked }) {
        let disabled = null;
        if (first_name === '' || last_name === '' || birthdate === '' || !checked) {
          disabled = true;
        } else {
          disabled = false;
        }
        this.$emit('inputPersonalInformation', { first_name, last_name, patronymic, birthdate, checked }, disabled);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    const { first_name, last_name, patronymic, birthdate, checked } = this.kyc;
    this.personalInformation = { first_name, last_name, patronymic, birthdate, checked };
  },
};
</script>

<style scoped lang='scss'>
.personal-information {
  &-container {
    margin-top: 8px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $gray600;
  }

  &__content {
    margin-top: 19px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  &__input {
    flex: 1 1 240px;
  }

  &__confirm {
    flex: 1;
    padding: 9px 16px;

    &.checked {
      background: $blue100;
      border-radius: 10px;
    }

    .awesome-checkbox-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    .awesome-custom-checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .awesome-checkbox-inner {
      position: relative;
      height: 16px;
      width: 16px;
      border: 1.5px solid $gray600;
      border-radius: 4px;
    }

    .awesome-checkbox-square {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 10px;
      width: 10px;
      background: $blue600;
      border-radius: 2px;
      display: none;
    }

    .awesome-custom-checkbox:checked ~ .awesome-checkbox-inner {
      border: 1.5px solid $blue600;
    }

    .awesome-custom-checkbox:checked ~ .awesome-checkbox-inner .awesome-checkbox-square {
      display: block;
    }

    .awesome-checkbox-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray600;

      &.checked {
        color: $blue600;
      }
    }
  }
}
</style>